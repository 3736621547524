export default {
  data () {
    return {
    }
  },
  mounted () { },
  methods: {
    //格式化 交易状态
    formatTradingStatus (val, list = this.dealState) {
      let str = "";
      list.some(item => {
        if (item.id == val) {
          str = item.name;
          return true;
        }
      })
      return str;
    },
    // 格式化 城市相关
    formatProvince (val, list = this.landProvinceList) {
      let str = "";
      list.some(item => {
        if (item.areaId == val) {
          str = item.name;
          return true;
        }
      })
      return str;
    },
    formatCity (val, list = this.cityList) {
      let str = "";
      list.some(item => {
        if (item.areaId == val) {
          str = item.name;
          return true;
        }
      })
      return str;
    },
    formatRegion (val, list = this.regionList) {
      let str = "";
      list.some(item => {
        if (item.areaId == val) {
          str = item.name;
          return true;
        }
      })
      return str;
    }
  }
}